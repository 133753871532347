/* You can add global styles to this file, and also import other style files */
//@import '~ngx-sharebuttons/themes/circles';

@import "nfluent";


.spacer {
  flex: auto;
}


.table-elevation {
  @extend .mat-elevation-z4;
  text-align: center;
  width: 94%;
  padding: 10px;
  margin: 2%;
}


bloc-bouton {
  font-size: small;
}


.item-menu {
  @extend .mat-caption;
  height: 20px;
}

.item-menu:hover {
  color: white;
}



mat-card {
  @extend .mat-body-2;
  padding: 7px;
  margin: 10px;
  text-align: left;

}


mat-card-content {
  padding: 7px;
}

mat-expansion-panel {
  width: 96% !important;
  margin: 2% !important;
  margin-top: 10px;
  margin-bottom: 10px;
}



//voir https://css-tricks.com/snippets/css/a-guide-to-flexbox/
.expansion-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  align-items: center;
  justify-content: space-around;
}

.expansion-content-left {
  @extend .expansion-content;
  justify-content: flex-start;
}



.faq-content {
  text-align: left;
}

.app-prompt {
  padding: 0;
  margin: 0;
  position: relative;
}

.app-prompt :host {
  filter: blur(2px);
}

button {
  width: fit-content;
  margin: 2px;
  padding: 1px;
}

th {
  @extend .background-component;
  @extend .color-primary;
  padding: 6px;
  font-size: medium;
  text-align: left;
}

.modal-popup-bg {
  width:100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  z-index: 100000;
  display: block;
  text-align: center;
  opacity: 0.95;
  pointer-events: none;
}

.modal-popup-bg :host {
  filter: blur(2px);
}

.modal-popup {
  display:block;
  position:fixed;
  left: 50%;
  top: 50%;
  width: 60%;
  max-width: 500px;
  transform: translate(-50%, -50%);
  font-size: 0.5em;
  z-index:100001;
  text-align: center;
  padding:50px;
  border: darkgrey solid 1px;
  opacity: 1 !important;
}


.background-form {
  text-align:left;
  width:100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.popup-form {
  position:relative;
  max-width: 700px;
  min-height: 100vh;
  display: inline-block;
  text-align: center;
  color: white;
  padding: 2%;
}



ul {
  list-style: none; /* Remove default bullets */
}


ul li::before {
  content: "\2022";
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  color: #00e726;
}

.greenline {
  z-index: -1;
  width: 100%;
  height: 22px;
  margin-top: -16px;
  display: block;
  background-color: #00e726 !important;
}

a:link { text-decoration: none;color: gray;  }
a:visited { text-decoration: none;color: gray;  }
a:hover { text-decoration: none; color: lightgray; }
a:active { text-decoration: none; color: gray; }

.mainform {
  padding: 0.2%;
  text-align: center;
}